import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import SiderBar from "./Layout/SiderBar";
import Header from "./Layout/Header";
import Home from "./Component/Home";
import Login from "./Component/Login";
import Profile from "./Component/Profile";
import ReferEran from "./Component/ReferEran";
import ViewBeetel from "./Component/ViewBeetel";
import Wallet from "./Component/Wallet";
import TopTenPlay from "./Component/TopTenPlay";
import Notification from "./Component/Notification";
import Onbid from "./Component/Onbid";
import HameHistory from "./Component/HameHistory";
import AppRoutes from "./Routes/Routes";

function App() {
  return (
    <div className="">
      {/* <Home/> */}
      {/* <Login /> */}
      {/* <Profile /> */}
      {/* <ReferEran /> */}
      {/* <ViewBeetel /> */}
      {/* <Wallet /> */}
      <AppRoutes />
    </div>
  );
}

export default App;
