import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allapiAction } from "../Redux/common/action";
import { SIDERBAR_BOS } from "../Redux/common/constant";
// import Sheet from "react-modal-sheet";
function Header2() {
  const [first, setfirst] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const otpBoxAction = (data) => {
    return { type: SIDERBAR_BOS, payload: data };
  };

  // profile

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(get_message);

  useEffect(() => {
    dispatch(allapiAction.getmessageuser({}));
    return () => {};
  }, []);

  const hendleSideBar = (e) => {
    dispatch(otpBoxAction(e));
  };
  const handleClick = (e) => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18next.changeLanguage(lang);
    }
  };
  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div>
      <div className="headerContainerms">
        {/* <div>
          {get_message?.first && (
            <div
              className={
                get_message?.first?.priority == "red"
                  ? "messagebox1"
                  : "messagebox2"
              }
            >
              <div class="collapseCard-text">{get_message?.first?.message}</div>
            </div>
          )}
        </div> */}
        <div className="position-relative">
          <div className="headerContainer headerContainer2">
            <Link to={"/intro"}>
              <picture className="ms-2 navLogo d-flex">
                <img src="../assest/image/logo2.png" alt="" />
              </picture>
            </Link>

            <div className="menu-items">
              <div id="walletBalance">
                <div className="menu-items">
                  {/* add-funds */}
                  {/* <Link to={"/add-funds"} className="box">
               <picture className="moneyIcon-container">
                 <img src="../assest/image/global-rupeeIcon.png" alt="" />
               </picture>
               <div className="mt-1 ms-1">
                 <div className="moneyBox-header">Cash</div>
                 <div className="moneyBox-text">
                   ₹{profile?.wallets?.closeBal.toFixed(2)}
                 </div>
               </div>
               <picture className="moneyBox-add">
                 <img src="../assest/image/global-addSign.png" alt="" />
               </picture>
             </Link>
         
             &nbsp; */}
                  {/* <Link className="box" to="/withdrawal-request">
               <picture className="moneyIcon-container">
                 <img src="../assest/image/reward_red.png" alt="" />
               </picture>
               <div className="mt-1 ms-1">
                 <div className="moneyBox-header">Winning</div>
                 <div className="moneyBox-text">
                   ₹{profile?.wallets?.winningBal}
                 </div>
               </div>
               <picture className="moneyBox-add"></picture>
             </Link> */}
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Guide
                  </button>
                </div>
                <span className="mx-5"> </span>
              </div>
            </div>
            {/* <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/l32zDMTNmY8?si=XNOkOgYEPeKim9dC"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet> */}
            <span className="mx-5"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header2;
