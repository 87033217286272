import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";

function Support() {

  const dispatch = useDispatch()
  const get_setting = useSelector((state) =>
    state?.allapi?.get_setting ? state?.allapi?.get_setting : {}
  );

  console.log(get_setting);

  useEffect(() => {
    // getaccuontdetails

    dispatch(allapiAction.getsetting());
    return () => {};
    // setaccountdetailsupdate(profile?.users?.accountId);
  }, []);

  return (
    <div>
      {" "}
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="cxy flex-column mx-4" style={{ marginTop: "70px" }}>
              <img src="../assest/image/contact_us.png" width="280px" alt="" />
              <div
                className="games-section-title mt-4 text-center"
                style={{ fontSize: "1em" }}
              >
                <p>Support timing is from 11:00 AM to 8:00 PM</p>
                <p>(Monday to Saturday)</p>
                <p>Email : - {get_setting?.vpa }</p>
                <p>Number : - {get_setting?.name }</p>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Support;
