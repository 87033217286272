import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allapiAction } from "../Redux/common/action";

function Success() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const amount = urlParams.get("amount");
  const transaction_id = urlParams.get("transaction_id");
  useEffect(() => {
    dispatch(allapiAction.addFound({ amount, transaction_id }, navigate));

    return () => {};
  }, []);


  useEffect(() => {
    // Access the query string from the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Get specific parameters
    const paymentId = queryParams.get('paymentId');
    const status = queryParams.get('status');

    // Process the payment-related data as needed
   

    // You can update your application state or perform further actions here
  }, []);

  return (
    <div className="w-full ">
      <div className="container">
        <div className="row   successorder">
          {/* <div className="col-12"> */}
          <img
            // style={{ width: "250px" }}
            src="../assest/image/success.png"
            alt=""
          />
          <h1 className="text-2xl font-semibold">Transaction Successfull</h1>
        </div>
      </div>
    </div>
  );
}

export default Success;
