import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Header2 from "../Layout/Header2";
import RightContainer from "./RightContainer";
import { useSelector } from "react-redux";

function IntroScreen() {
  const [box, setBox] = useState("0");
  const [otp, setOtp] = useState("");
  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );
  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <div id="menufade" onclick="closeMenu()" className=""></div> */}

        {/* <!------Nav Menu End------>

		<!------Hedar Start------> */}

        <div className="leftContainer">
          <Header2 />
          <div className="main-area" style={{ paddingTop: "60px" }}>
       
            <div style={{ overflowY: "hidden" }}>
            <div className="p-3">
              {get_message?.sec && (
                <div
                  className={
                    get_message?.sec?.priority == "red"
                      ? "messagebox9"
                      : "messagebox2"
                  }
                >
                  <div class="collapseCard-text">
                    {get_message?.sec?.message}
                  </div>
                </div>
              )}
            </div>
              {/* <div className="splash-overlay"></div> */}
              {/* <div className="splash-screen">
                <figure>
                  <img
                    width="100%"
                    src="../assest/image/global-gameSheetSplash.png"
                    alt=""
                  />
                </figure>
                <figure>
                  <img
                    width="100%"
                    src="../assest/image/landingNew.png"
                    alt=""
                  />
                </figure>
              </div> */}
              {/* <img width="100%" src="../assest/image/landingNew.png" alt="" /> */}
              {/* <p>
                This Game involves an element of financial risk and may be
                addictive. Please Play responsibly and at your own risk.
              </p> */}

              <div className="games-section-headline mt-2 mb-1">
                <div className="games-window">
                  <div className="gameCard-container">
                    <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>

                    <Link to={"/rules"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/ludocm.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Classic Manual</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link>

                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-Classic-auto"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/autoroomcode.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Classic Auto</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link> */}

                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-popular"} className="gameCard">
                      <picture className="gameCard-image">
                        <img width="100%" src="../assest/image/22.png" alt="" />
                      </picture>
                      <div className="gameCard-title">Ludo Popular</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link> */}
                    {/* <span className="blink text-danger d-block text-right">
                      ◉ Comming Soon
                    </span>

                    <a href={"#"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/SNAKELADDER.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">SNAKE & LADDER</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}

                    {/* <span className="blink text-danger d-block text-right">
                      ◉ Comming Soon
                    </span>
                    <a href={"#"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/teenpatti.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Teen Patti</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}
                  </div>

                  <div className="gameCard-container">
                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-Classic-auto"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/autoroomcode.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Classic Auto</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link> */}

                    <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/rules"} className="gameCard">
                      <picture className="gameCard-image">
                        <img width="100%" src="../assest/image/ludop.png" alt="" />
                      </picture>
                      <div className="gameCard-title">Ludo Popular</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link>
                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/snake-and-ladder"} className="gameCard">
                      <picture className="gameCard-image">
                        <img width="100%" src="../assest/image/11.png" alt="" />
                      </picture>
                      <div className="gameCard-title">Snake And Ladder</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link>
                    <span className="blink text-danger d-block text-right">
                      ◉ Comming Soon
                    </span> */}
                    {/* <a href={"#"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/cricket.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Cricket</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}
                    {/* <a
                      className="gameCard"
                      href=""
                    >
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src=""
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Popular</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}
                  </div>
                  {/* <!--<div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ LIVE</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/kb_ludo_classic.jpeg" alt="">
                            </picture>
                            <div className="gameCard-title">Ludo Popular</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    
    
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Live</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/kb_ludo.jpeg" alt="">
    
                            </picture>
                            <div className="gameCard-title">Ludo No Cut</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Live</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/kb_ludo_classic.jpeg" alt="">
                            </picture>
                            <div className="gameCard-title">Ludo Ulta</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    
    
    
    
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/rummy.png" alt="">
    
                            </picture>
                            <div className="gameCard-title">Rummy</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/teen.png" alt="">
                            </picture>
                            <div className="gameCard-title">Teen Patti</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    
    
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/fantasy-cricket.jpeg" alt="">
                            </picture>
                            <div className="gameCard-title">Snakes & Ladders</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/unnamed.webp" alt="">
                            </picture>
                            <div className="gameCard-title">Dragon Tiger</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    --> */}
                </div>
              </div>
              <div className="login-footer">
                <div className="row">
                  <div className="col-9">
                    <Link
                      to="/login"
                      class="bg-dark refer-button cxy mt-2 send-otp"
                      style={{ width: "100%" }}
                    >
                      Play Now
                    </Link>
                  </div>
                  <div className="col-2">
                    <a
                      class="bg-primary shadow-lg rounded-circle d-flex align-items-center justify-content-center text-white"
                      href="#/support"
                      style={{ height: "60px", width: "60px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="30"
                        height="30"
                        fill="currentColor"
                      >
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default IntroScreen;
