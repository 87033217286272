 
import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Header from "../Layout/Header";
// import SiderBar from "../Layout/SiderBar";
// import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import Header2 from "../Layout/Header2";

function Rules() {
  
  return (
    <div>
      {" "}
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <!------Nav Menu End------>

    <!------Hedar Start------> */}

        {/* <SiderBar /> */}

        <div className="leftContainer">
          <Header2 />
          <div className="main-area" style={{ paddingTop: "80px" }}>
            <div className="center-xy">
              
            <h6>Rules:</h6>
            <ol>
              <li>यदि दोनों प्लेयर्स ने कोड Join करके गेम प्ले कर लिया हो और दूसरा प्लेयर गोटी ओपन होने के बाद गेम लेफ्ट मारता है तो Opponent प्लेयर पूरा Lose दिया जायेगा</li>
              <li>यदि दोनों Players गेम प्ले के लिए स्टार्ट करते है एक प्लेयर गेम मे चला गया बल्कि दूसरा प्लेयर गेम मैं नही गया और गेम प्ले हो गया दोनों तरफ से एक प्लेयर की गोटी ओपन हो गई और दूसरा प्लेयर ऑटो Exit है जो ऑटो एग्जिट प्लेयर है वो पूरा लूज़ दिया जायेगा अतः अपना नेट प्रॉब्लम चेक करके खेले ये स्वयं की जिम्मेदारी होगी</li>
              <li>Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !</li>
              <li>Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !</li>
              <li>दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा]</li>
              <li>कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे</li>
              <li>अन्यथा वो कैंसिल ही माना जायेगा</li>
              <li>एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर</li>
              <li>Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं ? गेम का रिकॉड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रका तो नो फ्रेश आईडी ही टेक करे जिनका इंटरेस्ट हो वही टेक करे टेबल को ( इसका अंतिम रिजल्ट एडमिन देगा )</li>
            </ol>
            <h6>कमीशन दरें:</h6>
            <ol>
              <li>Battle below 250₹, 10% commission will be charged on battle amount. <br /> 250₹ से कम की बैटल, बैटल राशि पर 10% कमीशन लिया जाएगा।</li>
              <li>Battle between 250₹ to 500₹, flat 25₹ commission will be charged. <br /> 250₹ से 500₹ के बीच बैटल, फ्लैट 25₹ कमीशन लगेगा।</li>
              <li>Battle above 500₹, 5% commission will be charged on battle amount. <br /> 500₹ से ऊपर की बैटल, बैटल राशि पर 5% कमीशन लिया जाएगा।</li>
            </ol>
          
            </div>
            <div className="divider-x"></div>
            
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Rules;
