import React from "react";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";

function TopTenPlay() {
  return (
    <div>
      <div id="root">
        <SiderBar />
        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 1
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;JCIMT667{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 1
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 2
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;6OO6S847{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 3
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;FEQ5W606{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 4
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;9C7DL201{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 5
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;4GC9C913{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 6
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;BC5YE844{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 7
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;5J1OF706{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 8
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;7HY1K706{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 9
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;8HUL5960{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
            <div className="d-flex px-4 py-3 list-item">
              <picture
                className="mr-3"
                style={{ fontSize: "20px", color: "#c1c1c1" }}
              >
                # 10
              </picture>
              <img
                className="border-50"
                height="30px"
                width="30px"
                src="../assest/image/avatars/Avatar2.png"
                alt=""
              />
              <div style={{ marginTop: "-7px" }}>
                <span
                  className="font-9 fw-800 d-flex"
                  style={{ fontSize: "20px" }}
                >
                  {" "}
                  &nbsp;JJQFC509{" "}
                </span>
                <small className="d-flex font-7 " style={{ lineHeight: "7px" }}>
                  {" "}
                  &nbsp; Total Win: 0
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default TopTenPlay;
