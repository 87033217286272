import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import Slider from "react-slick";
import { URL } from "../Redux/common/url";
import api from "../Redux/common/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import i18next from "i18next";
import Languageoption from "./language-dropdown";
import { useTranslation } from "react-i18next";
import RightContainer from "./RightContainer";
function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alredy, setalredy] = useState(false);
  const [userName, setUserName] = useState(false);
  const [file, setFile] = useState(false);
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [selectlan, setselectlan] = useState("en");

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  const get_metrics = useSelector((state) =>
    state?.allapi?.get_metrics ? state?.allapi?.get_metrics : {}
  );

  useEffect(() => {
    dispatch(allapiAction.metricsUser());
    return () => {};
  }, []);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => {
    setShow(true);
    setUserName(profile?.users?.uid);
  };
  const handleShow1 = () => {
    setShow1(true);
    // setUserName(profile?.users?.uid);
  };
  // profile

  const hendleTochangeuserName = async (e) => {
    const token = await localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    // dispatch(loading(true));
    setUserName(e);
    const response = await api.post(`${URL.checkuserName}`, { uid: e }, config);

    if (response?.data?.succes) {
      setalredy(true);
    } else {
      setalredy(false);
    }
  };

  const logout = async () => {
    if (typeof window !== "undefined") {
      await localStorage.clear();
    }

    navigate("/login");
  };

  const hendleToSubmit = () => {
    if (alredy) {
      toast.error("already exist");
    } else if (userName?.length <= 4) {
      toast.error("invalid ");
    } else {
      setShow(false);
      dispatch(allapiAction.edituserId({ uid: userName }));
      return () => {};
    }
  };

  // editprofileImage

  const hendletoImageupload = () => {
    setShow1(false);
    const data1 = new FormData();

    data1.append("image", file);
    dispatch(allapiAction.editprofileImage(data1));
    return () => {};
  };

  useEffect(() => {
    // console.log(e?.target?.value);
    const lang = localStorage.getItem("lang");
    if (lang) {
      setselectlan(lang);
      // i18next.changeLanguage(lang);
    }
  }, []);

  const handleClick = (e) => {
    setselectlan(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <div id="menufade" onclick="closeMenu()" className=""></div> */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alredy && <p style={{ color: "red" }}>allready exist</p>}
            {userName?.length <= 4 && (
              <p style={{ color: "red" }}>not valid </p>
            )}
            <input
              value={userName}
              className="form-control"
              onChange={(e) => {
                hendleTochangeuserName(e?.target?.value);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={hendleToSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="file"
              name="screenshot"
              className="form-control"
              required=""
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={hendletoImageupload}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="p-3" style={{ background: "rgb(250, 250, 250)" }}>
              <div>
                <div className="row">
                  <div className="col-4">
                    <picture>
                      <img
                        className="border-50"
                        height="80px"
                        width="80px"
                        src={
                          profile?.users?.image
                            ? URL?.API_BASE_URL + profile?.users?.image
                            : "../assest/image/Avatar2.png"
                        }
                        alt=""
                      />
                      <img
                        className=""
                        width="20px"
                        style={{ marginLeft: "-15px" }}
                        src={"../assest/image/icon-edit.jpg"}
                        alt=""
                        onClick={handleShow1}
                      />
                    </picture>
                  </div>
                  <div className="col-8 text-start">
                    <span className="battle-input-header mr-1">
                      +91{profile?.users?.number}
                    </span>
                    <div className="text-bold my-3 vplay_id">
                      {profile?.users?.uid}
                      <img
                        className="ms-2"
                        width="20px"
                        src="../assest/image/icon-edit.jpg"
                        alt=""
                        onClick={handleShow}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="propayBox">
                <div className="profileFirstBox">
                  <div className="row">
                    <div className="col-4">
                      {" "}
                      <p>
                        {t("balance")}
                        {/* TOTAL BALANCE */}
                      </p>
                    </div>
                    <div className="col-4">
                      <p>₹ {profile?.wallets?.closeBal.toFixed(2)}</p>
                    </div>
                    <div className="col-4">
                      {" "}
                      <Link to={"/add-funds"}> {t("addcash")} </Link>{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      {" "}
                      <p>
                      Winnig balance
                        {/* TOTAL BALANCE */}
                      </p>
                    </div>
                    <div className="col-4">
                      <p>₹ {profile?.wallets?.winningBal.toFixed(2)}</p>
                    </div>
                    <div className="col-4">
                      {" "}
                      {/* <Link to={"/add-funds"}> {t("addcash")} </Link>{" "} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      {" "}
                      <p>
                        {t("earning")}
                        {/* TOTAL BALANCE */}
                      </p>
                    </div>
                    <div className="col-4">
                      <p>₹ {profile?.wallets?.earning.toFixed(2)}</p>
                    </div>
                    <div className="col-4">
                      {" "}
                      {/* <Link to={"/add-funds"}> {t("addcash")} </Link>{" "} */}
                    </div>
                  </div>

                  {profile?.users?.keyverify !== "complete" && (
                    <Link
                      className="d-flex align-items-center profile-wallet bg-light mx-1 my-4 py-3"
                      // href="#"
                      to={"/kyc-complete"}
                      tabindex="0"
                    >
                      <picture className="ms-4">
                        <img
                          width="32px"
                          src="../assest/image/kyc-icon-new.png"
                          alt=""
                        />
                      </picture>
                      <div className="ms-5  text-muted">{t("kyccomplete")}</div>
                    </Link>
                  )}

                  {/* <Languageoption
                    selectlan={selectlan}
                    onChange={(e) => handleClick(e)}
                  /> */}
                  <div className="wibtn">
                    <Link
                      to={"/withdrawal-request"}
                      className={"btn-danger btn-lg "}
                    >
                      Withdrawal
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider-x"></div>

            <div className="p-4 bg-light">
              <div className="row">
                <div className="col-6">
                  <div className="box2">
                    <div className="row">
                      <div className="col-4">
                        {" "}
                        <img
                          className=""
                          width="25px"
                          //   style={{ marginLeft: "-15px" }}
                          src={"../assest/image/gamepad.png"}
                          alt=""
                        />
                      </div>
                      <div className="col-8">
                        <h6>{get_metrics?.gameCount}</h6>
                        <p>Games Played</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="box2">
                    <div className="row">
                      <div className="col-4">
                        {" "}
                        <img
                          className=""
                          width="25px"
                          //   style={{ marginLeft: "-15px" }}
                          src={"../assest/image/winner.png"}
                          alt=""
                        />
                      </div>
                      <div className="col-8">
                        <h6>{profile?.wallets?.earning}</h6>
                        <p>Win </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="box2">
                    <div className="row">
                      <div className="col-4">
                        {" "}
                        <img
                          className=""
                          width="25px"
                          //   style={{ marginLeft: "-15px" }}
                          src={"../assest/image/refer.png"}
                          alt=""
                        />
                      </div>
                      <div className="col-8">
                        <h6>{get_metrics?.referamount}</h6>
                        <p>Referral Eraning</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="box2">
                    <div className="row">
                      <div className="col-4">
                        {" "}
                        <img
                          className=""
                          width="25px"
                          //   style={{ marginLeft: "-15px" }}
                          src={"../assest/image/bail.png"}
                          alt=""
                        />
                      </div>
                      <div className="col-8">
                        <h6>{get_metrics?.penltyamount}</h6>
                        <p>Penalty</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="p-3">
              <div className="text-bold">Complete Profile</div>
              <div className="d-flex">
                <div style={{ overflowX: "hidden" }}>
                  <div
                    className="react-swipeable-view-container slick-initialized slick-slider"
                    style={{
                      flexDirection: "row",
                      transition:
                        "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                      direction: "ltr",
                      display: "flex",
                      willChange: "transform",
                      transform: "translate(0%, 0px)",
                    }}
                  >
                    <button
                      type="button"
                      className="slick-prev pull-left slick-arrow"
                      style={{
                        border: "0px solid white",
                        backgroundColor: "white",
                        fontSize: "35px",
                        color: "rgb(150, 150, 150)",
                      }}
                      fdprocessedid="js4b1"
                    >
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </button>

                    <div className="slick-list draggable">
                      <div style={{ marginLeft: "35px" }}>
                      
                        <Slider {...settings}>
                          <div>
                            <div
                              aria-hidden="true"
                              data-swipeable="true"
                              style={{
                                width: "399px",
                                flexShrink: "0",
                                overflow: "auto",
                              }}
                              className="slick-slide slick-cloned"
                              data-slick-index="-1"
                              id=""
                              tabindex="-1"
                            >
                              <a
                                className="d-flex align-items-center profile-wallet bg-light mx-1 my-4 py-3"
                                onclick="showUpdateForm()"
                                tabindex="-1"
                              >
                                <picture className="ms-4">
                                  <img
                                    width="32px"
                                    src="../assest/image/mail.png"
                                    alt=""
                                  />
                                </picture>
                                <div className="ms-5 mytext text-muted">
                                  Update Email ID
                                </div>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div
                              aria-hidden="false"
                              data-swipeable="true"
                              style={{
                                width: "399px",
                                flexShrink: "0",
                                overflow: "auto",
                              }}
                              className="slick-slide slick-current slick-active"
                              data-slick-index="0"
                              tabindex="0"
                            >
                              <Link
                                className="d-flex align-items-center profile-wallet bg-light mx-1 my-4 py-3"
                                // href="#"
                                to={"/kyc-complete"}
                                tabindex="0"
                              >
                                <picture className="ms-4">
                                  <img
                                    width="32px"
                                    src="../assest/image/kyc-icon-new.png"
                                    alt=""
                                  />
                                </picture>
                                <div className="ms-5 mytext text-muted">
                                  Complete Your KYC
                                </div>
                              </Link>
                            </div>
                          </div>
                         
                        </Slider>
                      </div>
                   
                    </div>

                    <button
                      type="button"
                      className="slick-next pull-right slick-arrow"
                    
                      style={{
                        border: "0px solid white",
                        backgroundColor: "white",
                        fontSize: "35px",
                        color: "rgb(150, 150, 150)",
                      }}
                      fdprocessedid="0bn1b"
                    >
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="divider-x"></div>
            {/* <div className="px-3 py-1">
              <div
                className="d-flex align-items-center position-relative"
                style={{ height: "84px" }}
              >
                <picture>
                  <img
                    height="32px"
                    width="32px"
                    src="../assest/image/sidebar-referEarn.png"
                    alt=""
                  />
                </picture>
                <div className="pl-4">
                  <div
                    className="text-uppercase moneyBox-header"
                    style={{ fontSize: "0.8em" }}
                  >
                    Use Refer Code
                  </div>
                  <div className="refferForm">
                    <div
                      className="MuiFormControl-root MuiTextField-root"
                      style={{ verticalAlign: "bottom" }}
                    >
                      <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                        <input
                          aria-invalid="false"
                          type="text"
                          className="MuiInputBase-input MuiInput-input refferalID"
                          value=""
                          fdprocessedid="2xn7m"
                        />
                      </div>
                    </div>
                    <img
                      className="ms-2"
                      onclick="saveRefferBy()"
                      width="20px"
                      src="../assest/image/select-blue-checkIcon.png"
                      alt=""
                    />
                  </div>
                  <div className="refferIdshow">
                    <div
                      className="MuiFormControl-root MuiTextField-root"
                      //   style="vertical-align: bottom"
                      style={{ verticalAlign: "bottom" }}
                    >
                      <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="px-3 py-1">
              <div
                className="d-flex align-items-center position-relative"
                // style="height: 84px"
                style={{ height: "84px" }}
              >
                <picture>
                  <img
                    height="32px"
                    width="32px"
                    src="../assest/image/global-cash-won-green-circular.png"
                    alt=""
                  />
                </picture>
                <div className="pl-4">
                  <div
                    className="text-uppercase moneyBox-header"
                    // style="font-size: 0.8em"
                    style={{ fontSize: "0.8em" }}
                  >
                    Cash Won
                  </div>
                  <div>
                    <picture className="mr-1">
                      <img
                        height="auto"
                        width="21px"
                        src="../assest/image/global-rupeeIcon.png"
                        alt=""
                      />
                    </picture>
                    <span
                      className="moneyBox-text"
                      style={{ fontSize: "1em", bottom: "-1px" }}
                    >
                      ₹ 0
                    </span>
                  </div>
                  <span className="thin-divider-x"></span>
                </div>
              </div>
            </div> */}

            {/* <div className="px-3 py-1">
              <div
                className="d-flex align-items-center position-relative"
                // style="height: 84px"
                style={{ height: "84px" }}
              >
                <picture>
                  <img
                    height="32px"
                    width="32px"
                    src="../assest/image/global-purple-battleIcon.png"
                    alt=""
                  />
                </picture>
                <div className="pl-4">
                  <div
                    className="text-uppercase moneyBox-header"
                    // style="font-size: 0.8em"
                    style={{ fontSize: "0.8em" }}
                  >
                    Battle Played
                  </div>
                  <div>
                    <span
                      className="moneyBox-text"
                      style={{ fontSize: "1em", bottom: "-1px" }}
                    >
                      3
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="divider-x"></div>
            <div className="p-3">
              <a
                // href="#
                // onclick="event.preventDefault();  document.getElementById('logout-form').submit();"
                className="center-xy  btn btn-primary text-uppercase "
                onClick={() => {
                  logout();
                }}
              >
                Log Out
              </a>
            </div>
            <form
              id="logout-form"
              action="#"
              method="POST"
              style={{ display: "none" }}
            >
              <input
                type="hidden"
                name="_token"
                value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
              />
            </form>

            <div className="kyc-select" style={{ display: "none" }}>
              <div className="overlay"></div>
              <div
                className="box"
                style={{ bottom: "0px", position: "absolute" }}
              >
                <div className="bg-white">
                  <div className="header cxy flex-column">
                    <picture>
                      <img
                        className="border-50"
                        height="80px"
                        width="80px"
                        src="../assest/image/avatars/Avatar2.png"
                        alt=""
                      />
                    </picture>
                    <div className="custom-file mt-4">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="profilePic"
                        name="profilePic"
                        accept="image/*"
                      />
                      <label className="custom-file-label" for="screenshot">
                        Browse your profile pic
                      </label>
                    </div>
                    <span className="mt-2">OR</span>
                    <div className="header-text mt-2">Choose Avatar</div>
                  </div>
                  <div className="mx-3 pb-3" style={{ paddingTop: "300px" }}>
                    <div className="row justify-content-between col-10 mx-auto">
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar1.png"
                        alt=""
                      />
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar2.png"
                        alt=""
                      />
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar3.png"
                        alt=""
                      />
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar4.png"
                        alt=""
                      />
                    </div>
                    <div className="row justify-content-between col-10 mx-auto mt-3">
                      <img
                        height="50px"
                        width="50px"
                        src="/images/avatars/Avatar5.png"
                        alt=""
                      />
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar6.png"
                        alt=""
                      />
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar7.png"
                        alt=""
                      />
                      <img
                        height="50px"
                        width="50px"
                        src="../assest/image/avatars/Avatar8.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="kyc-select" style={{ display: "none" }}>
              <div
                className="overlay"
                id="overlay_id"
                onclick="closeUpdateForm()"
                style={{ pointerEvents: "auto", opacity: "0.87" }}
              ></div>
              <div
                className="box kyc-select-enter-done"
                style={{ bottom: "0px", position: "absolute" }}
              >
                <div className="bg-white">
                  <div className="header cxy flex-column">
                    <div className="header-text mt-2">Update Email</div>
                  </div>
                  <div className="mx-3 pb-3" style={{ paddingTop: "130px" }}>
                    <form action="#" method="post">
                      <input
                        type="hidden"
                        name="_token"
                        value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
                      />
                      <div className="MuiFormControl-root MuiTextField-root d-flex m-auto w-80">
                        <label
                          className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated"
                          data-shrink="false"
                        >
                          Enter Email
                        </label>
                        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                          <input
                            aria-invalid="false"
                            type="email"
                            name="email"
                            className="MuiInputBase-input MuiInput-input"
                            value="  "
                          />
                        </div>
                      </div>
                      <button
                        className="btn btn-success mt-3 text-uppercase d-flex mx-auto"
                        style={{ fontWeight: "500" }}
                      >
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
        {/* <div className="rightContainer">
      <div className="rcBanner flex-center">
        <picture className="rcBanner-img-container">
          <img
            src="../assest/image/Header_profile.jpeg"
            alt=""
          />
        </picture>
        <div className="rcBanner-text">
           RK Ludo King <span className="rcBanner-text-bold">Win Real Cash!</span>
        </div>
        <div className="rcBanner-footer">
          For best experience, open&nbsp;<a
            href="#!"
            style="
              color: rgb(44, 44, 44);
              font-weight: 500;
              text-decoration: none;
            "
            > RK Ludo King.In</a
          >&nbsp;on&nbsp;<img
            src="../assest/image/global-chrome.png"
            alt=""
          />&nbsp;chrome mobile
        </div>
      </div>
    </div> */}
      </div>
    </div>
  );
}

export default Profile;
