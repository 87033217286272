import axios from "axios";
import { URL } from "./url";

let Api = axios.create({
  baseURL: URL.API_BASE_URL,
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status == 401) {

      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);
export default Api;
