import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import Api from "../Redux/common/api";
import { URL } from "../Redux/common/url";
import PaymentForm from "./Payment/PaymentForm";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
function Addfund3() {
  const { t, i18n } = useTranslation();

  const [amount, setamount] = useState("");
  const [transaction_id, settransaction_id] = useState("");

  const [redirectUrl, setRedirectUrl] = useState("");
  const [error, setError] = useState("");
  const [screen, setScreen] = useState("1");

  const dispatch = useDispatch();
  const navigete = useNavigate();

  const get_setting = useSelector((state) =>
    state?.allapi?.get_setting ? state?.allapi?.get_setting : {}
  );
  const get_admin_ac = useSelector((state) =>
    state?.allapi?.get_admin_ac ? state?.allapi?.get_admin_ac : {}
  );

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  //   addFound

  const [uploadbtn, setuploadbtn] = useState(true);

  const hendleToAddFund = async (e) => {
    setuploadbtn(false);
    if (!amount) {
      toast.error("Please enter amount");
      setuploadbtn(true);
    } else if (get_setting?.minimumrecharge > amount) {
      toast.error("minimum rechage " + get_setting?.minimumrecharge);
      setuploadbtn(true);
    } else if (get_setting?.maxmumrecharge < amount) {
      toast.error("maxmum rechage" + get_setting?.maxmumrecharge);
      setuploadbtn(true);
    } else if (!transaction_id) {
      toast.error("Please enter transaction");
    }  else if (!file) {
      toast.error("Please select screen shot");
      setuploadbtn(true);
    } else {
      const userToken = localStorage.getItem("access_user");
      var user = JSON.parse(userToken);
      const data1 = new FormData();

      data1.append("amount", amount);
      data1.append("paymentIn", get_admin_ac?.upiid);
      data1.append("transactionId", transaction_id);
      data1.append("image", file);

      await dispatch(allapiAction.addfundreqest(data1, navigete));
      setuploadbtn(true);
      setFile(null);
      return () => {};
    }
  };
  const hendleToAddFund2 = async (e) => {
    if (!amount) {
      toast.error("Please enter amount");
    } else if (get_setting?.minimumrecharge > amount) {
      toast.error("minimum rechage " + get_setting?.minimumrecharge);
    } else if (get_setting?.maxmumrecharge < amount) {
      toast.error("maxmum rechage" + get_setting?.maxmumrecharge);
    } else {
      setScreen("1");
      return () => {};
    }
  };

  const hendleToAddFund3 = async (e) => {
    if (!amount) {
      toast.error("Please enter amount");
    } else if (get_setting?.minimumrecharge > amount) {
      toast.error("minimum rechage " + get_setting?.minimumrecharge);
    } else if (get_setting?.maxmumrecharge < amount) {
      toast.error("maxmum rechage" + get_setting?.maxmumrecharge);
    } else {
      // setScreen("1");
      // createorderforupi
      const responsem = await dispatch(
        allapiAction.createorderforupi({ amount: amount })
      );

      console.log(responsem?.data?.data?.data);
      if (responsem?.data?.success) {
        // setRedirectUrl(
        //   responsem?.data?.data?.data.instrumentResponse?.redirectInfo?.url
        // );

        if (responsem?.data?.data?.data.payment_url) {
          window.location.href = responsem?.data?.data?.data.payment_url;
        }
      } else {
      }
      return () => {};
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getsetting());
    dispatch(allapiAction.getaccountListdetails());

    return () => {};
  }, []);

  const handleScroll = (e) => {
    e.preventDefault(); // Prevent scrolling from affecting the input value
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (!isNaN(newValue)) {
      setamount(newValue);
    }
  };

  function copy() {
    const el = document.createElement("input");
    el.value = get_admin_ac?.upiid;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");
  }
  return (
    <div>
      <div id="root">
        <Header />

        <div class="leftContainer">
          <SiderBar />

          <div class="main-area" style={{ paddingTop: "100px" }}>
            <div class="px-4 py-3">
              {screen == "1" && (
                <div className="">
                  <div class="games-section">
                    {/* {screen == "1" && (
                      <div className="row">
                        <div className="col-8">Amount to be add ₹{amount}</div>
                        <div className="col-4">
                          <button
                            type="button"
                            class="bg-green playButton2 position-static mt-2 "
                            onClick={(e) => {
                              setScreen("0");
                            }}
                          >
                            Edit
                            <img
                              src="/assest/image/pencil.png"
                              alt=""
                              style={{ width: "15px", paddingLeft: "5px" }}
                            />
                          </button>
                        </div>
                      </div>
                    )} */}
                    <div class="d-flex position-relative align-items-center">
                      <div class="games-section-title"></div>
                    </div>
                  </div>
                  {/* {screen == "1" && (
                    <div class="games-section">
                      <div class="d-flex position-relative align-items-center">
                        <div class="games-section-title">
                          {get_admin_ac?.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {screen == "1" && (
                    <div class="games-window">
                      <div className="">
                        <img
                          src={URL?.API_BASE_URL + get_admin_ac?.qrimg}
                          width="100%"
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  {screen == "1" && (
                    <div class="games-section">
                      <div class="d-flex position-relative align-items-center">
                        <div class="games-section-title">
                          {get_admin_ac?.upiid}

                          <button
                            className="bg-green playButton position-static mt-2 ms-5"
                            onClick={() => {
                              copy();
                            }}
                            fdprocessedid="iamgla"
                          >
                            Copy{" "}
                            <img
                              src="/assest/image/copy.png"
                              alt=""
                              style={{ width: "20px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              )}

              <div class="pb-3 mb-5 propayBox">
              <div className="topprob">Upi Information</div>
                {screen == "1" && (
                  <div class="MuiFormControl-root mt-4 MuiFormControl-fullWidth p-3">
                    <div class="MuiFormControl-root MuiTextField-root">
                    <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink mt-1"
                        data-shrink="true"
                      >
                 
               Copy  Upi Information
                      </label>
                      <div class="games-section-title upibox">
                          {get_admin_ac?.upiid}

                          <button
                            className="bg-green playButton   upibox2 "
                            onClick={() => {
                              copy();
                            }}
                            fdprocessedid="iamgla"
                          >
                            Copy{" "}
                            <img
                              src="/assest/image/copy.png"
                              alt=""
                              style={{ width: "20px" }}
                            />
                          </button>
                        </div>


                      <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink"
                        data-shrink="true"
                      >
                        {t("enteramount")}
                  
                      </label>
                      <div class="MuiInputBase-root MuiInput-root  jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                        {/* <div class="MuiInputAdornment-root MuiInputAdornment-positionStart">
                          <p class="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                            ₹
                          </p>
                        </div> */}

                        <input
                          name="amount"
                          type="number"
                          class="form-control"
                          id="Textfield"
                          min="10"
                          max="10000"
                          placeholder="Enter Amount"
                          autocomplete="off"
                          fdprocessedid="k7wnmc"
                          value={amount}
                          onWheel={handleScroll}
                          // onChange={(e) => {
                          //   handleInputChange(e.target.value);
                          // }}
                          onChange={handleInputChange}
                        />
                      </div>

                      <p class="MuiFormHelperText-root">
                        {" "}
                        {t("min")}: {get_setting?.minimumrecharge}, {t("max")}:{" "}
                        {get_setting?.maxmumrecharge}
                      </p>

                      <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink mt-1"
                        data-shrink="true"
                      >
                 
                        Enter UTR
                      </label>
                      <div class="MuiInputBase-root MuiInput-root   jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                      

                        <input
                          name="amount"
                          type="text"
                          class="form-control"
                          id="Textfield"
                          min="10"
                          max="10000"
                          required=""
                          autocomplete="off"
                          fdprocessedid="k7wnmc"
                          placeholder="Enter UTR"
                          value={transaction_id}
                          onChange={(e) => {
                            settransaction_id(e.target.value);
                          }}
                        />
                      </div>
                      <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink mt-1"
                        data-shrink="true"
                      >
                        {/* {t("enteramount")} */}
                        Select Screen shot
                      </label>
                      <div class="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                        {/* <div class="MuiInputAdornment-root MuiInputAdornment-positionStart">
                        <p class="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                          ₹
                        </p>
                      </div> */}

                        <input
                          type="file"
                          name="screenshot"
                          className="form-control"
                          required=""
                          accept="image/*"
                          onChange={(e) => {
                            handleFileChange(e);
                          }}
                        />
                      </div>
                     

                      <p class="regud">
                        Please enter the REF NO/ Referance No/ UTR (12-digit number) of your transfer and we will finish your recharge soon as possible.
                      </p>
                    </div>
                  </div>
                )}

                {screen == "0" && (
                  <div class="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
                    <div class="MuiFormControl-root MuiTextField-root">
                      <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink"
                        data-shrink="true"
                      >
                        {t("enteramount")}
                        {/* Enter Amount */}
                      </label>
                      <div class="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                        <div class="MuiInputAdornment-root MuiInputAdornment-positionStart">
                          <p class="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                            ₹
                          </p>
                        </div>

                        <input
                          name="amount"
                          type="number"
                          class="MuiInputBase-input"
                          id="Textfield"
                          min="10"
                          max="10000"
                          required=""
                          autocomplete="off"
                          fdprocessedid="k7wnmc"
                          value={amount}
                          onWheel={handleScroll}
                          // onChange={(e) => {
                          //   handleInputChange(e.target.value);
                          // }}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {screen == "0" && (
                  <div class="games-window">
                    <div
                      class="gameCard-container"
                      id="amount1"
                      onClick={() => {
                        setamount(100);
                      }}
                    >
                      <div class="add-fund-box">
                        <div
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <div
                            class="collapseCard-title mr-1"
                            style={{ fontSize: "0.9em" }}
                          >
                            ₹
                          </div>
                          <div
                            class="collapseCard-title"
                            style={{ fontSize: "1.5em" }}
                          >
                            100
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gameCard-container"
                      id="amount2"
                      onClick={() => {
                        setamount(250);
                      }}
                    >
                      <div class="add-fund-box">
                        <div
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <div
                            class="collapseCard-title mr-1"
                            style={{ fontSize: "0.9em" }}
                          >
                            ₹
                          </div>
                          <div
                            class="collapseCard-title"
                            style={{ fontSize: "1.5em" }}
                          >
                            250
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gameCard-container"
                      id="amount3"
                      onClick={() => {
                        setamount(500);
                      }}
                    >
                      <div class="add-fund-box">
                        <div
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <div
                            class="collapseCard-title mr-1"
                            style={{ fontSize: "0.9em" }}
                          >
                            ₹
                          </div>
                          <div
                            class="collapseCard-title"
                            style={{ fontSize: "1.5em" }}
                          >
                            500
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gameCard-container"
                      id="amount4"
                      onClick={() => {
                        setamount(1000);
                      }}
                    >
                      <div class="add-fund-box">
                        <div
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <div
                            class="collapseCard-title mr-1"
                            style={{ fontSize: "0.9em" }}
                          >
                            ₹
                          </div>
                          <div
                            class="collapseCard-title"
                            style={{ fontSize: "1.5em" }}
                          >
                            1000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-3">

               
                {screen == "0" ? (
                  <button
                    // type="submit"
                    class="disabledButton btn btn-primary btn-lg mt-2"
                    style={{ width: "100%" }}
                    fdprocessedid="zez14v"
                    onClick={(e) => {
                        hendleToAddFund2();
                    }}
                  >
                    NEXT
                  </button>
                ) : uploadbtn ? (
                  <button
                    // type="submit"
                    class="disabledButton btn btn-primary btn-lg  mt-2"
                    style={{ width: "100%" }}
                    fdprocessedid="zez14v"
                    onClick={(e) => {
                      hendleToAddFund(e);
                    }}
                  >
                    NEXT
                  </button>
                ) : (
                  <button
                    // type="submit"
                    class="disabledButton btn btn-primary btn-lg  mt-2"
                    style={{ width: "100%" }}
                    fdprocessedid="zez14v"
                    // onClick={(e) => {
                    //   hendleToAddFund(e);
                    // }}
                  >
                    Wait for upload image
                  </button>
                )}
                </div>
              </div>
              <div class="refer-footer">
                {/* {screen == "0" ? (
                  <button
                    // type="submit"
                    class="disabledButton btn btn-primary btn-lg"
                    style={{ width: "100%" }}
                    fdprocessedid="zez14v"
                    onClick={(e) => {
                      hendleToAddFund2("1");
                    }}
                  >
                    NEXT
                  </button>
                ) : (
                  <button
                    // type="submit"
                    class="disabledButton btn btn-primary btn-lg"
                    style={{ width: "100%" }}
                    fdprocessedid="zez14v"
                    onClick={(e) => {
                      hendleToAddFund(e);
                    }}
                  >
                    NEXT
                  </button>
                )} */}
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>

        <div class="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Addfund3;
